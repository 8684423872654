<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入姓名、电话搜索" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
        infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
         <div class="list_row">
                <div class="flex">
                    <div><label for="">姓名：</label>{{item.wname}}</div>
                    <div><label for="">性别：</label>{{item.sex}}</div>
                    <!-- <div><label for="">服务人员编号：</label>{{item.id}}</div> -->
                </div>
                <div class="flex">
                    <div><label for="">电话：</label>{{item.wtel}}</div>
                    <!-- <div><label for="">职务：</label>{{item.post}}</div>        -->
                    <div><label for="">底薪：</label>{{item.base}}</div>                  
                </div>
                 <div class="flex">
                    <div><label for="">所属部门：</label>{{item.department}}</div>
                    <div><label for="">状态：</label>{{item.status}}</div>
                    <!-- <div><label for="">跟进人：</label>{{item.sname}}</div> -->
                 </div>
                  <div class="flex">
                    <div><label for="">保洁证：</label>{{item.cleaning}}</div>
                    <div><label for="">绿化证：</label>{{item.green}}</div>
                    <!-- <div><label for="">跟进人：</label>{{item.sname}}</div> -->
                 </div>
                 
                 <div class="flex">
                    <div><label for="">工作点：</label>{{item.oname}}</div>
                    <!-- <div><label for="">跟进人：</label>{{item.sname}}</div> -->
                 </div>
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="toDetail(item.id)">查看</a-button>
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
                <a-button type="primary" size="small" ghost @click="showAllot(item.id)">分配工作点</a-button>
                <!-- <a-button type="primary" size="small" ghost @click="showStatus(item.id)">修改状态</a-button> -->
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
            </div>
        </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
         <a-modal v-model="showAllotModal" :centered="true" title="分配工作点"  @ok="setAllot">
            <div class="addBox">
                <div class="add_title">请选择工作点</div>
                <a-select v-model="onAllot" style="width:300px" class="ant-select-lg">
                    <a-select-option  :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.oname}}</a-select-option>
                </a-select>
            </div>
            <div class="addBox">
                <div class="add_title">请选择部门</div>
                <a-select v-model="departmentid" style="width:300px" class="ant-select-lg">
                    <a-select-option :value="item.did" v-for="(item,index) in departList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </div>
        </a-modal>
        <a-modal v-model="showStatusModal" :centered="true" title="修改状态"  @ok="setStatus">
            <div class="addBox">
                <div class="add_title" style="margin-bottom:30px">请选择状态</div>
                  <a-select v-model="onStatus" show-search option-filter-prop="children" class="ant-select-lg" style="width: 200px;">
                          <a-select-option :value="1">在职</a-select-option>
                        <a-select-option :value="2">离职</a-select-option>
                    </a-select>
            </div>
        </a-modal>
          <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";

import {requestXml,ajaxUrl} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},  
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            ajaxUrl:ajaxUrl,
            sex:['','男','女'],  //性别
            status:['','在职','离职'],  //工作状态
            green:['','无','有'],
            showAllotModal:false, //工作点分配窗口
            onAllot:'',  //已选分配工作点
            selectedRow:'', //已选服务人员
            operatList:'', //工作点列表
            showStatusModal:false, //状态分配窗口
            onStatus:'', //已选状态
            // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
            departList:'',//部门列表
            departmentid:'',//选择部门id
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'key':this.key,'opid':this.selOpid,'status':this.selStatus,'cleaning':this.selcleaning,'green':this.selGreen,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/worker/workerDetail" || this.fromPath == '/scrm_wap/worker/workerAdd') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.key
            this.selOpid=data.opid
            this.selStatus=data.status
            this.selcleaning=data.cleaning
            this.selGreen=data.green
        }
        this.getData();
        this.getOperat();
        this.getDepartList()
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
        // 分页
        pagesChange(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData();
        },
        // 获取工作点
        getOperat(){
             requestXml("/jieb/Operating/operatshow","POST",(res) => {
                this.operatList = res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        
        // 获取部门
        getDepartList() {
           requestXml("/jieb/Target/department", "POST", (res) => {
               this.departList = res;
           });
        },
        // 显示状态
        showStatus(id){
            this.selectedRow=id;
            this.showStatusModal=true; 
        },
        // 设置状态
        setStatus(){
            if(!this.onStatus){
                this.$message.info('请选择状态');
                return false;
            }else{
                requestXml("/jieb/Worker/statusedit","POST",() => {
                    this.showStatusModal=false;
                    this.onStatus='';
                    this.getData();
                },{id:[this.selectedRow],status:this.onStatus})
            }
        },
          // 分配工作点 窗口
        showAllot(id){
            this.selectedRow=id;
            this.showAllotModal=true;
        },
        // 分配工作点
        setAllot(){
            if(!this.onAllot){
                this.$message.info('请选择工作点');
                return false;
            }else if(!this.departmentid) {
                this.$message.info('请选择部门');
            }else{
                requestXml("/jieb/Worker/operatsedit","POST",(res) => {
                    if(res=="success"){
                        // this.$message.success('分配成功');
                        this.showAllotModal=false;
                        this.onAllot='';
                        this.departmentid = '';
                        this.onSearch();
                    }
                },{id:[this.selectedRow],opid:this.onAllot,did: this.departmentid})
            }
        },


        toDetail(id){
             this.$router.push({
                path: "/scrm_wap/worker/workerDetail",
                query: {
                    id: id,
                }
            });
        },
        
        getData() {
                this.dataLoadStop = true;
                requestXml("/jieb/Worker/workershow","POST",(res) => {
                console.log(res);
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sex =  this.sex[Number(res.list[i].sex)];
                    res.list[i].status = this.status[Number(res.list[i].status)];
                    res.list[i].cleaning =this.green[res.list[i].cleaning];
                    res.list[i].green=this.green[res.list[i].green]; 
                }
                this.data = res.list
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{key:this.key,opid:this.selOpid,status:this.selStatus,cleaning:this.selcleaning,green:this.selGreen,
                  page: {
                        curpage: this.pagination.current,
                        pagesize: this.pagination.pageSize,
                    },
            })
        },
      // 编辑
        edit(id){
           this.$router.push({
                path: "/scrm_wap/worker/workerAdd",
                query: {
                    id: id,
                }
            });
        },
        // 删除
        onDelete(id){
            requestXml("/jieb/Worker/workerdel","POST",(res) => {
                console.log(res)
                 this.pagination.current=1;
                this.data=[];
                this.getData();
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
  .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }
    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
    .lists .item{
        display: flex;
    }    
    .lists .right{
        text-align: left;
        padding-left: 20px;
        width: calc(100% - 100px );
    }
     .lists .right .flex{
         display: flex;
         justify-content: space-between;
     }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
    .add_title{
        padding-top: 10px;padding-bottom: 5px;
    }
    
</style>
